define("boondmanager/modifiers/on-visible", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; } /* eslint-disable prettier/prettier */
  /**
   * Modifier to call a callback when child element is visible
   * Using IntersectionObserver https://developer.mozilla.org/fr/docs/Web/API/IntersectionObserver
   */
  var _default = _exports.default = (0, _emberModifier.modifier)(function intersectionObserver(element, _ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
      callback = _ref3[0];
    var _ref2$delay = _ref2.delay,
      delay = _ref2$delay === void 0 ? 100 : _ref2$delay;
    var timeout;
    if (element._bmLazyLoadTriggered) {
      return; // Exit early if this element has already been handled
    }

    var observer = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting && !timeout) {
          timeout = window.setTimeout(function () {
            if (entry.isIntersecting) {
              Promise.resolve(callback()).then(function (result) {
                var event = new CustomEvent('bmLazyLoad', {
                  detail: {
                    data: result
                  }
                });
                element._bmLazyLoadTriggered = true;
                element.dispatchEvent(event);
              });
              observer.disconnect(); // Disconnect after the first intersection
            }
          }, delay);
        } else {
          clearTimeout(timeout);
          timeout = null;
        }
      });
    });
    observer.observe(element);
    return function () {
      clearTimeout(timeout);
      observer.disconnect(); // Cleanup when the element is destroyed
    };
  });
});