define("boondmanager/pods/components/octane/bm-lazyload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hwAXJe3s",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,5,[32,0,[\"inlineStyle\"]]],[16,\"data-visible\",[30,[36,0],[[32,0,[\"isVisible\"]],1,0],null]],[17,2],[4,[38,3],[[30,[36,2],[[32,0,[\"onVisible\"]]],null],[35,1]],null],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"isVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"delay\",\"fn\",\"on-visible\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-lazyload/template.hbs"
  });
});