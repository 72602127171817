define("boondmanager/pods/components/octane/bm-alerts/opportunity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0yq44JP8",
    "block": "{\"symbols\":[\"item\",\"relatedEntity\",\"index\"],\"statements\":[[6,[37,1],[[32,0,[\"warnings\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-alerts\",[],[[\"@type\"],[\"warning\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"warnings\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[32,1,[\"message\"]]],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,1,[\"relatedEntities\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,1],[[32,3],\", \"],null]],[2,\"\\n\"],[6,[37,1],[[32,2,[\"hasAccess\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"related-entity bm-alerts__entity\"]],[[\"@route\",\"@model\"],[[32,2,[\"route\"]],[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[1,[32,2,[\"label\"]]],[2,\"\\n\\t\\t\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\\t\\t\"],[10,\"strong\"],[14,0,\"bm-alerts__entity\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[1,[32,2,[\"label\"]]],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2,3]}]]],[6,[37,1],[[32,1,[\"warning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[10,\"p\"],[12],[1,[30,[36,0],[[32,1,[\"warning\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/opportunity/template.hbs"
  });
});