define("boondmanager/helpers/format-bytes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatBytes = formatBytes;
  function formatBytes(bytes) {
    var units = ['o', 'ko', 'Mo', 'Go', 'To', 'Po'];
    var pow = Math.floor(Math.log(bytes) / Math.log(1024));
    return "".concat(Math.round(bytes / Math.round(Math.pow(1024, pow))), " ").concat(units[pow]);
  }
  var _default = _exports.default = Ember.Helper.helper(formatBytes);
});