define("boondmanager/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OKgJ8lL9",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\n\"],[8,\"octane/bm-cleanup-splash-screen\",[],[[],[]],null],[2,\"\\n\"],[8,\"octane/bm-debug-toolbar\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "moduleName": "boondmanager/pods/application/template.hbs"
  });
});