define("boondmanager/helpers/format-ms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatMs = formatMs;
  function formatMs(milliseconds) {
    milliseconds = parseFloat(milliseconds);
    var units = [{
      label: 'ms',
      value: 1
    }, {
      label: 's',
      value: 1000
    }, {
      label: 'min',
      value: 1000 * 60
    }, {
      label: 'h',
      value: 1000 * 60 * 60
    }];
    var unit = units[0]; // Par défaut en millisecondes

    // Trouver la plus grande unité utilisable
    for (var i = units.length - 1; i >= 0; i--) {
      if (milliseconds >= units[i].value) {
        unit = units[i];
        break;
      }
    }
    var value = milliseconds / unit.value;
    return "".concat(Math.round(value * 100) / 100, " ").concat(unit.label);
  }
  var _default = _exports.default = Ember.Helper.helper(formatMs);
});