define("boondmanager/settings/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.messages = messages;
  function messages(i18n, args) {
    if (args) {
      args.interpolation = {
        escapeValue: false
      };
    }
    return {
      error: {
        '400': {
          details: i18n.t('messages:error.400.details', args),
          message: i18n.t('messages:error.400.message', args),
          title: i18n.t('messages:error.400.title', args)
        },
        '401': {
          details: i18n.t('messages:error.401.details', args),
          message: i18n.t('messages:error.401.message', args),
          title: i18n.t('messages:error.401.title', args)
        },
        '403': {
          message: i18n.t('messages:error.403.message', args),
          title: i18n.t('messages:error.403.title', args)
        },
        '404': {
          message: i18n.t('messages:error.404.message', args),
          title: i18n.t('messages:error.404.title', args)
        },
        '511': {
          message: i18n.t('messages:error.511.message', args),
          title: i18n.t('messages:error.511.title', args)
        },
        '422': {
          message: i18n.t('messages:error.422.message', args),
          title: i18n.t('messages:error.422.title', args)
        },
        '500': {
          message: i18n.t('messages:error.500.message', args),
          title: i18n.t('messages:error.500.title', args)
        },
        '501': {
          message: i18n.t('messages:error.501.message', args),
          title: i18n.t('messages:error.501.title', args)
        },
        '503': {
          message: i18n.t('messages:error.503.message', args),
          title: i18n.t('messages:error.503.title', args),
          nextStep: i18n.t('messages:error.503.nextStep', args)
        },
        '666': {
          details: i18n.t('messages:error.666.details', args),
          message: i18n.t('messages:error.666.message', args),
          title: i18n.t('messages:error.666.title', args)
        },
        '1000': {
          details: i18n.t('messages:error.1000.details', args)
        },
        '1001': {
          details: i18n.t('messages:error.1001.details', args),
          message: i18n.t('messages:error.1001.message', args)
        },
        '1002': {
          details: i18n.t('messages:error.1002.details', args)
        },
        '1003': {
          details: i18n.t('messages:error.1003.details', args)
        },
        '1004': {
          details: i18n.t('messages:error.1004.details', args)
        },
        '1029': {
          details: i18n.t('messages:error.1029.details', args)
        },
        '1050': {
          details: i18n.t('messages:error.1050.details', args)
        },
        '1051': {
          details: i18n.t('messages:error.1051.details', args)
        },
        '1052': {
          details: i18n.t('messages:error.1052.details', args)
        },
        '1007': {
          details: i18n.t('messages:error.1007.details', args)
        },
        '1008': {
          details: i18n.t('messages:error.1008.details', args),
          message: i18n.t('messages:error.1008.message', args),
          title: i18n.t('messages:error.1008.title', args)
        },
        '1009': {
          details: i18n.t('messages:error.1009.details', args),
          message: i18n.t('messages:error.1009.message', args)
        },
        '1012': {
          details: i18n.t('messages:error.1012.details', args)
        },
        '1013': {
          details: i18n.t('messages:error.1013.details', args)
        },
        '1017': {
          details: i18n.t('messages:error.1017.details', args)
        },
        '1022': {
          details: i18n.t('messages:error.1022.details', args),
          message: i18n.t('messages:error.1022.message', args),
          title: i18n.t('messages:error.1022.title', args)
        },
        '1023': {
          details: i18n.t('messages:error.1023.details', args),
          message: i18n.t('messages:error.1023.message', args),
          title: i18n.t('messages:error.1023.title', args)
        },
        '1027': {
          details: i18n.t('messages:error.1027.details', args),
          title: i18n.t('messages:error.1027.title', args)
        },
        '1028': {
          details: i18n.t('messages:error.1028.details', args),
          title: i18n.t('messages:error.1028.title', args)
        },
        '1100': {
          details: i18n.t('messages:error.1100.details', args),
          message: i18n.t('messages:error.1100.message', args),
          title: i18n.t('messages:error.1100.title', args)
        },
        '1101': {
          details: i18n.t('messages:error.1101.details', args),
          message: i18n.t('messages:error.1101.message', args),
          title: i18n.t('messages:error.1101.title', args)
        },
        '1102': {
          details: i18n.t('messages:error.1102.details', args),
          message: i18n.t('messages:error.1102.message', args),
          title: i18n.t('messages:error.1102.title', args)
        },
        '1103': {
          details: i18n.t('messages:error.1103.details', args),
          message: i18n.t('messages:error.1103.message', args),
          title: i18n.t('messages:error.1103.title', args)
        },
        '1104': {
          details: i18n.t('messages:error.1104.details', args),
          message: i18n.t('messages:error.1104.message', args),
          title: i18n.t('messages:error.1104.title', args)
        },
        '1105': {
          details: i18n.t('messages:error.1105.details', args),
          message: i18n.t('messages:error.1105.message', args),
          title: i18n.t('messages:error.1105.title', args)
        },
        '1108': {
          details: i18n.t('messages:error.1108.details', args),
          message: i18n.t('messages:error.1108.message', args),
          title: i18n.t('messages:error.1108.title', args)
        },
        '1110': {
          details: i18n.t('messages:error.1110.details', args)
        },
        '1201': {
          details: i18n.t('messages:error.1201.details', args),
          message: i18n.t('messages:error.1201.message', args),
          title: i18n.t('messages:error.1201.title', args)
        },
        '1202': {
          details: i18n.t('messages:error.1202.details', args)
        },
        '1203': {
          details: i18n.t('messages:error.1203.details', args)
        },
        '1204': {
          details: i18n.t('messages:error.1204.details', args)
        },
        '1211': {
          details: i18n.t('messages:error.1211.details', args)
        },
        '1212': {
          details: i18n.t('messages:error.1212.details', args)
        },
        '2001': {
          details: i18n.t('messages:error.2001.details', args)
        },
        '2101': {
          details: i18n.t('messages:error.2101.details', args)
        },
        '2203': {
          details: i18n.t('messages:error.2203.details', args)
        },
        '2301': {
          details: i18n.t('messages:error.2301.details', args)
        },
        '2405': {
          details: i18n.t('messages:error.2405.details', args)
        },
        '2501': {
          details: i18n.t('messages:error.2501.details', args)
        },
        '2502': {
          details: i18n.t('messages:error.2502.details', args)
        },
        '2503': {
          details: i18n.t('messages:error.2503.details', args)
        },
        '2516': {
          details: i18n.t('messages:error.2516.details', args)
        },
        '2610': {
          details: i18n.t('messages:error.2610.details', args)
        },
        '3101': {
          details: i18n.t('messages:error.3101.details', args)
        },
        '3300': {
          details: i18n.t('messages:error.3300.details', args)
        },
        '3302': {
          details: i18n.t('messages:error.3302.details', args)
        },
        '3303': {
          details: i18n.t('messages:error.3303.details', args)
        },
        '3313': {
          details: i18n.t('messages:error.3313.details', args)
        },
        '3403': {
          details: i18n.t('messages:error.3403.details', args)
        },
        '3502': {
          details: i18n.t('messages:error.3502.details', args)
        },
        '3510': {
          details: i18n.t('messages:error.3510.details', args)
        },
        '3604': {
          details: i18n.t('messages:error.3604.details', args)
        },
        '3804': {
          details: i18n.t('messages:error.3804.details', args)
        },
        '3700': {
          details: i18n.t('messages:error.3700.details', args)
        },
        '3701': {
          details: i18n.t('messages:error.3701.details', args)
        },
        '3810': {
          details: i18n.t('messages:error.3810.details', args)
        },
        '3905': {
          details: i18n.t('messages:error.3905.details', args)
        },
        '3910': {
          details: i18n.t('messages:error.3910.details', args)
        },
        '3911': {
          details: i18n.t('messages:error.3911.details', args)
        },
        '3912': {
          details: i18n.t('messages:error.3912.details', args)
        },
        '4301': {
          details: i18n.t('messages:error.4301.details', args)
        },
        '4306': {
          details: i18n.t('messages:error.4306.details', args)
        },
        '4700': {
          details: i18n.t('messages:error.4700.details', args)
        },
        '4802': {
          details: i18n.t('messages:error.4802.details', args)
        },
        '10100': {
          details: i18n.t('messages:error.10100.details', args)
        },
        '10101': {
          details: i18n.t('messages:error.10101.details', args)
        },
        '10200': {
          details: i18n.t('messages:error.10200.details', args)
        },
        '10310': {
          details: i18n.t('messages:error.10310.details', args)
        },
        '10311': {
          details: i18n.t('messages:error.10311.details', args)
        },
        '10312': {
          details: i18n.t('messages:error.10312.details', args)
        },
        '10501': {
          details: i18n.t('messages:error.10501.details', args)
        },
        '10700': {
          details: i18n.t('messages:error.10700.details', args)
        },
        '10701': {
          details: i18n.t('messages:error.10701.details', args)
        },
        '10702': {
          details: i18n.t('messages:error.10702.details', args)
        },
        '10703': {
          details: i18n.t('messages:error.10703.details', args)
        },
        '10800': {
          details: i18n.t('messages:error.10800.details', args)
        },
        '11100': {
          details: i18n.t('messages:error.11100.details', args)
        },
        '11101': {
          details: i18n.t('messages:error.11101.details', args)
        },
        '11102': {
          details: i18n.t('messages:error.11102.details', args)
        },
        '11103': {
          details: i18n.t('messages:error.11103.details', args)
        },
        '11104': {
          details: i18n.t('messages:error.11104.details', args)
        },
        '11105': {
          details: i18n.t('messages:error.11105.details', args)
        },
        '13000': {
          details: i18n.t('messages:error.13000.details', args)
        },
        '13001': {
          details: i18n.t('messages:error.13001.details', args)
        },
        '14000': {
          details: i18n.t('messages:error.14000.details', args)
        },
        '14001': {
          details: i18n.t('messages:error.14001.details', args)
        },
        '15007': {
          details: i18n.t('messages:error.15007.details', args)
        },
        nointernetconnection: {
          message: i18n.t('messages:error.nointernetconnection.message', args),
          title: i18n.t('messages:error.nointernetconnection.title', args)
        },
        certifiedNotGenerated: {
          details: i18n.t('messages:error.certifiedNotGenerated.details', args),
          list: i18n.t('messages:error.certifiedNotGenerated.list', args),
          cause: i18n.t('messages:error.certifiedNotGenerated.cause', args)
        },
        textualErrors: {
          maxLength: i18n.t('messages:error.textualErrors.maxLength', args),
          maxLength_plural: i18n.t('messages:error.textualErrors.maxLength_plural', args),
          maxLengthGeneric: i18n.t('messages:error.textualErrors.maxLengthGeneric', args),
          minLength: i18n.t('messages:error.textualErrors.minLength', args),
          minLength_plural: i18n.t('messages:error.textualErrors.minLength_plural', args),
          minLengthGeneric: i18n.t('messages:error.textualErrors.minLengthGeneric', args)
        }
      },
      warnings: {
        houraccounts: {
          absenceWarningDay: i18n.t('houraccounts:warnings.absenceWarningDay', args),
          absenceWarningDay_plural: i18n.t('houraccounts:warnings.absenceWarningDay_plural', args),
          absenceWarningEmpty: i18n.t('houraccounts:warnings.absenceWarningEmpty', args),
          absenceWarningEmpty_plural: i18n.t('houraccounts:warnings.absenceWarningEmpty_plural', args),
          absenceWarningHalfDay: i18n.t('houraccounts:warnings.absenceWarningHalfDay', args),
          absenceWarningHalfDay_plural: i18n.t('houraccounts:warnings.absenceWarningHalfDay_plural', args),
          warningDayInconsistency: i18n.t('houraccounts:warnings.warningDayInconsistency', args),
          warningDayInconsistency_plural: i18n.t('houraccounts:warnings.warningDayInconsistency_plural', args),
          warningEmptyContractDay: i18n.t('houraccounts:warnings.warningEmptyContractDay', args),
          warningEmptyContractDay_plural: i18n.t('houraccounts:warnings.warningEmptyContractDay_plural', args),
          warningOutOfContract: i18n.t('houraccounts:warnings.warningOutOfContract', args),
          warningOutOfContract_plural: i18n.t('houraccounts:warnings.warningOutOfContract_plural', args),
          warningPartialContractDay: i18n.t('houraccounts:warnings.warningPartialContractDay', args),
          warningPartialContractDay_plural: i18n.t('houraccounts:warnings.warningPartialContractDay_plural', args),
          warningRestDay: i18n.t('houraccounts:warnings.warningRestDay', args),
          warningRestMinutes: i18n.t('houraccounts:warnings.warningRestMinutes', args),
          warningRestMinutes_plural: i18n.t('houraccounts:warnings.warningRestMinutes_plural', args),
          warningRestWeek: i18n.t('houraccounts:warnings.warningRestWeek', args)
        },
        appBoondmanagerError: i18n.t('messages:warnings.appBoondmanagerError', args),
        appEmailingUseSendingTypeGmail: i18n.t('messages:warnings.appEmailingUseSendingTypeGmail', args),
        appEmailingUseSendingTypeGmailForWriteEmail: i18n.t('messages:warnings.appEmailingUseSendingTypeGmailForWriteEmail', args),
        appEmailingWrongAuthentication: i18n.t('messages:warnings.appEmailingWrongAuthentication', args),
        appEmailingWrongAuthenticationForWriteEmail: i18n.t('messages:warnings.appEmailingWrongAuthenticationForWriteEmail', args),
        appEmailingMicrosoftBlockUnknownConnections: i18n.t('messages:warnings.appEmailingMicrosoftBlockUnknownConnections', args),
        appError: i18n.t('messages:warnings.appError', args),
        appOauthRedirection: i18n.t('messages:warnings.appOauthRedirection', args),
        appOauthRedirectionForOtherUser: i18n.t('messages:warnings.appOauthRedirectionForOtherUser', args),
        atLeastOneAbsenceQuotaExceeded: i18n.t('messages:warnings.atLeastOneAbsenceQuotaExceeded', args),
        billingScheduleTotalAmountDifferent: i18n.t('messages:warnings.billingScheduleTotalAmountDifferent', args),
        consumption: {
          expensesOutsideDeliveryDates: i18n.t('messages:warnings.expensesOutsideDeliveryDates', args)
        },
        deliveryDoesNotExist: i18n.t('messages:warnings.deliveryDoesNotExist', args),
        deliveryDoesNotExistForResource: i18n.t('messages:warnings.deliveryDoesNotExistForResource', args),
        expensesOutsideContractDates: i18n.t('messages:warnings.expensesOutsideContractDates', args),
        expensesOutsideDeliveryDates: i18n.t('messages:warnings.expensesOutsideDeliveryDates', args),
        expensesOutsideDeliveryDatesWithTitle: i18n.t('messages:warnings.expensesOutsideDeliveryDatesWithTitle', args),
        expensesReportExists: i18n.t('messages:warnings.expensesReportExists', args),
        expensesReportExists_plural: i18n.t('messages:warnings.expensesReportExists_plural', args),
        invoiceDateModified: i18n.t('messages:warnings.invoiceDateModified', args),
        invoiceDateModifiedList: i18n.t('messages:warnings.invoiceDateModifiedList', args),
        invoiceDateModifiedList_item: i18n.t('messages:warnings.invoiceDateModifiedList_item', args),
        invoiceDateModifiedList_plural: i18n.t('messages:warnings.invoiceDateModifiedList_plural', args),
        invoiceHasNotMergeableFiles: i18n.t('messages:warnings.invoiceHasNotMergeableFiles', args),
        lessThanNumberOfWorkingDaysInsideContractDates: i18n.t('messages:warnings.lessThanNumberOfWorkingDaysInsideContractDates', args),
        moreThanAbsenceAccountAcquired: i18n.t('messages:warnings.moreThanAbsenceAccountAcquired', args),
        moreThanNumberOfWorkingDays: i18n.t('messages:warnings.moreThanNumberOfWorkingDays', args),
        moreThanNumberOfWorkingDaysInsideContractDates: i18n.t('messages:warnings.moreThanNumberOfWorkingDaysInsideContractDates', args),
        noBankDetail: {
          addBankDetail: i18n.t('messages:warnings.noBankDetail.addBankDetail', args),
          order: i18n.t('messages:warnings.noBankDetail.order', args),
          invoice: i18n.t('messages:warnings.noBankDetail.invoice', args)
        },
        noBillingDetail: {
          addBillingDetail: i18n.t('messages:warnings.noBillingDetail.addBillingDetail', args),
          invoice: i18n.t('messages:warnings.noBillingDetail.invoice', args),
          order: i18n.t('messages:warnings.noBillingDetail.order', args),
          purchase: i18n.t('messages:warnings.noBillingDetail.purchase', args),
          quotation: i18n.t('messages:warnings.noBillingDetail.quotation', args)
        },
        noContract: {
          external: i18n.t('messages:warnings.noContract.external', args),
          internal: i18n.t('messages:warnings.noContract.internal', args)
        },
        noDeliveryOnProject: i18n.t('messages:warnings.noDeliveryOnProject', args),
        noDeliveryOnProjectForResource: i18n.t('messages:warnings.noDeliveryOnProjectForResource', args),
        nonSignedContract: i18n.t('messages:warnings.nonSignedContract', args),
        nonSignedContract_plural: i18n.t('messages:warnings.nonSignedContract_plural', args),
        orderHasNoCompany: i18n.t('messages:warnings.orderHasNoCompany', args),
        orderSchedulesDeltaTotals: i18n.t('messages:warnings.orderSchedulesDeltaTotals', args),
        outsideContractDates: i18n.t('messages:warnings.outsideContractDates', args),
        outsideDeliveryDates: i18n.t('messages:warnings.outsideDeliveryDates', args),
        outsideDeliveryDatesForExpensesReport: i18n.t('messages:warnings.outsideDeliveryDatesForExpensesReport', args),
        outsideDeliveryDatesForExpensesReport_plural: i18n.t('messages:warnings.outsideDeliveryDatesForExpensesReport_plural', args),
        outsideDeliveryDatesForResource: i18n.t('messages:warnings.outsideDeliveryDatesForResource', args),
        outsideDeliveryDatesForResourceWithTitle: i18n.t('messages:warnings.outsideDeliveryDatesForResourceWithTitle', args),
        outsideDeliveryDatesForTimesReport: i18n.t('messages:warnings.outsideDeliveryDatesForTimesReport', args),
        outsideDeliveryDatesForTimesReport_plural: i18n.t('messages:warnings.outsideDeliveryDatesForTimesReport_plural', args),
        outsideDeliveryDatesWithTitle: i18n.t('messages:warnings.outsideDeliveryDatesWithTitle', args),
        outsideDocumentDates: i18n.t('messages:warnings.outsideDocumentDates', args),
        overlappingContractDates: i18n.t('messages:warnings.overlappingContractDates', args),
        overlappingDeliveryAndProjectDates: i18n.t('messages:warnings.overlappingDeliveryAndProjectDates', args),
        overlappingPositioningAndOpportunityDates: i18n.t('messages:warnings.overlappingPositioningAndOpportunityDates', args),
        positioningWithDatesWhereasOpportunityStartImmediate: i18n.t('messages:warnings.positioningWithDatesWhereasOpportunityStartImmediate', args),
        projectDoesNotExist: i18n.t('messages:warnings.projectDoesNotExist', args),
        quotationHasNoCompany: i18n.t('messages:warnings.quotationHasNoCompany', args),
        timesOutsideContractDates: i18n.t('messages:warnings.timesOutsideContractDates', args),
        timesOutsideDeliveryDates: i18n.t('messages:warnings.timesOutsideDeliveryDates', args),
        timesReportExists: i18n.t('messages:warnings.timesReportExists', args),
        timesReportExists_plural: i18n.t('messages:warnings.timesReportExists_plural', args),
        workplaceTimesLessThanNumberOfWorkingDays: i18n.t('messages:warnings.workplaceTimesLessThanNumberOfWorkingDays', args),
        workplaceTimesMoreThanNumberOfWorkingDays: i18n.t('messages:warnings.workplaceTimesMoreThanNumberOfWorkingDays', args),
        wrongAbsences: {
          oneReportDay: i18n.t('messages:warnings.wrongAbsences.oneReportDay', args),
          pluralReports: i18n.t('messages:warnings.wrongAbsences.pluralReports', args),
          orphanAbsences: {
            linkToCreate: i18n.t('messages:warnings.wrongAbsences.orphanAbsences.linkToCreate', args),
            oneReportDay: i18n.t('messages:warnings.wrongAbsences.orphanAbsences.oneReportDay', args)
          }
        },
        wrongAbsencesAdd: i18n.t('messages:warnings.wrongAbsencesAdd', args),
        wrongContractAverageDailyCost: i18n.t('messages:warnings.wrongContractAverageDailyCost', args),
        wrongContractAverageDailyCostCandidate: i18n.t('messages:warnings.wrongContractAverageDailyCostCandidate', args),
        wrongHierarchyManager: i18n.t('messages:warnings.wrongHierarchyManager', args),
        doctemplateCannotFindVariable: i18n.t('messages:warnings.doctemplateCannotFindVariable', args),
        doctemplateCannotFindVariable_plural: i18n.t('messages:warnings.doctemplateCannotFindVariable_plural', args),
        doctemplateIncompatibleInputOutputType: i18n.t('messages:warnings.doctemplateIncompatibleInputOutputType', args),
        doctemplateCannotFindModifier: i18n.t('messages:warnings.doctemplateCannotFindModifier', args),
        doctemplateCannotFindModifier_plural: i18n.t('messages:warnings.doctemplateCannotFindModifier_plural', args),
        workUnitRatesNotEqual: i18n.t('messages:warnings.workUnitRatesNotEqual', args),
        workUnitRatesNotEqualForTimesReport: i18n.t('messages:warnings.workUnitRatesNotEqualForTimesReport', args),
        workUnitRatesNotEqualForTimesReport_plural: i18n.t('messages:warnings.workUnitRatesNotEqualForTimesReport_plural', args)
      }
    };
  }
});