define("boondmanager/helpers/moment-from-now-mini", ["exports", "ember-moment/utils/helper-compute", "ember-moment/helpers/-base"], function (_exports, _helperCompute, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // params[0] : date
  // Paramètres nommés :
  // withTimes = true // afficher aussi le delta sur les heures
  // convertToAbsolute = true // Force un retour en valeur absolue
  var _default = _exports.default = _base.default.extend({
    moment: Ember.inject.service(),
    i18n: Ember.inject.service(),
    globalAllowEmpty: false,
    compute: (0, _helperCompute.default)(function (params, namedParams) {
      var _params$;
      this._super.apply(this, arguments);
      if (!params || params && params.length < 1) {
        throw new TypeError('moment-from-now-mini: Invalid Number of arguments, must be at least 1');
      }
      var moment = this.get('moment');
      var i18n = this.get('i18n');
      var dateToCount = params[0];
      var i18nKeyBase = (_params$ = params[1]) !== null && _params$ !== void 0 ? _params$ : 'common:dateAndTime.momentFromNowMini';
      var withDetails = !!namedParams.withTimes;
      var convertToAbsolute = !!namedParams.convertToAbsolute;
      var diffInDays = moment.moment().diff(dateToCount, 'days');
      var diffInMonths = moment.moment().diff(dateToCount, 'months');
      var diffInYears = moment.moment().diff(dateToCount, 'years');
      var diffInHours = moment.moment().diff(dateToCount, 'hours');
      var diffInMins = moment.moment().diff(dateToCount, 'mins');
      if (convertToAbsolute) {
        diffInDays = Math.abs(diffInDays);
        diffInMonths = Math.abs(diffInMonths);
        diffInYears = Math.abs(diffInYears);
        diffInHours = Math.abs(diffInHours);
        diffInMins = Math.abs(diffInMins);
      }
      if (diffInYears) {
        return i18n.t(i18nKeyBase + '.years.abbr', {
          count: diffInYears
        });
      } else if (diffInMonths) {
        return i18n.t(i18nKeyBase + '.months.abbr', {
          count: diffInMonths
        });
      } else {
        if (withDetails) {
          if (diffInHours) {
            return i18n.t(i18nKeyBase + '.hours.abbr', {
              count: diffInHours
            });
          } else {
            return i18n.t(i18nKeyBase + '.minutes.abbr', {
              count: diffInMins
            });
          }
        } else {
          return i18n.t(i18nKeyBase + '.days.abbr', {
            count: diffInDays
          });
        }
      }
    })
  });
});