define("boondmanager/pods/private/advancedreportings/advancedreporting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+/j2K7Qw",
    "block": "{\"symbols\":[\"__arg0\"],\"statements\":[[8,\"octane/bm-layout-search\",[],[[\"@title\",\"@icon\",\"@module\",\"@featureFlags\",\"@namedBlocksInfo\"],[[32,0,[\"model\",\"title\"]],\"bmi-reporting\",\"advancedreporting\",[32,0,[\"featureFlags\"]],[30,[36,2],null,[[\"searchFields\",\"content\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"searchFields\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"octane/bm-search-filters/views/advanced-reporting\",[[24,0,\"bmc-search-fields\"]],[[\"@module\",\"@params\",\"@onUpdate\",\"@saveSearchEnabled\"],[[32,0,[\"model\",\"module\"]],[32,0,[\"activeQueryParams\"]],[32,0,[\"updateSearchFilters\"]],false]],null],[2,\"\\n\\t\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"octane/bm-advanced-reporting-dashboard\",[],[[\"@model\",\"@params\"],[[32,0,[\"model\"]],[32,0,[\"activeQueryParams\"]]]],null],[2,\"\\n\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"-is-named-block-invocation\",\"if\",\"hash\"]}",
    "moduleName": "boondmanager/pods/private/advancedreportings/advancedreporting/template.hbs"
  });
});