define("boondmanager/pods/components/octane/bm-alerts/delivery-standard-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FIv5LHo2",
    "block": "{\"symbols\":[],\"statements\":[[8,\"octane/bm-alerts\",[],[[\"@type\"],[\"warning\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item bmc-alert_list-item-flex\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,0],[\"deliveries:view.warnings.isStandardProfile\"],null]],[2,\"\\n\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-rectangle bmb-compact\"],[24,\"data-name\",\"button-assign-resource\"],[16,\"aria-label\",[30,[36,0],[\"deliveries:actions.assignResource\"],null]]],[[\"@onClick\"],[[32,0,[\"onClick\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,1],[\"bmi-user-edit\"],null]],[2,\" \"],[1,[30,[36,0],[\"deliveries:actions.assignResource\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"icon\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/delivery-standard-profile/template.hbs"
  });
});